const BACKSPACE = 8
const ENTER = 13
export function defaultValue(event, defaultVal) {
  let res = event.target.value
  if (
    event.target.value.length === 0 &&
    event.keyCode != BACKSPACE &&
    event.keyCode != ENTER
  ) {
    res = defaultVal
  }
  return res
}
