const rules = {
  email: [
    v => !!v || 'Es requerido para identificarte',
    v => /.+@.+\..+/.test(v) || 'Ej.: sara.rua@henkel.com',
    v => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(henkel.com|gmail.com|linebip.com)$/
      return pattern.test(v) || 'Sólo correo corporativo'
    }
  ],
  anyEmail: [
    v => !!v || 'Es requerido para enviarle la invitación',
    v => /.+@.+\..+/.test(v) || 'Por ejemplo: el.ciclo@papeleriaelretorno.com',
    v => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(v) || 'Algo no anda bien con este correo'
    }
  ],
  optionalEmail: [
    v => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (v)
        return (
          pattern.test(v) ||
          'Esperando a que termines de escribir un correo válido'
        )
      else return true
    }
  ],
  alias: [
    v => !!v || 'Es requerido para saludarlo :)',
    v => /.+/.test(v) || 'Por ejemplo: Xampoom',
    v => {
      const pattern = /^[A-Za-zá-úÁ-ÚñÄ-Üä-ü -]{2,96}$/m
      return pattern.test(v) || 'Sólo letras y espacios'
    }
  ],
  intlCode: [v => /^[+]\d{1,3}$/m.test(v) || 'Ejemplos: +52 ó +001'],
  optionalIntlCode: [
    v => {
      const pattern = /^[+]\d{1,3}$/m
      if (v) return pattern.test(v) || 'Ejemplos: +52 ó +001'
      else return true
    }
  ],
  phone: [v => /^\d{10}$/m.test(v) || 'Por ejemplo: 3312345678'],
  optionalPhone: [
    v => {
      const pattern = /^\d{10}$/m
      if (v) return pattern.test(v) || 'Por ejemplo: 3312345678'
      else return true
    }
  ],
  int2d: [
    v => !!v || 'Sin fantasmas :)',
    v => /^[1-9]{1}$/m.test(v) || 'Por ejemplo: 2'
  ]
}

export default rules
